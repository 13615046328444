:root {
  --hell-grau: #c6c7c9;
  --dunkel-grau: #999;
  --sehr-dunkel-grau: #000;
  --slogan-font: 'Arial Black', Gadget, sans-serif;
  --main-font: 'Helvetica', sans-serif
}
 
html, body {
  height: 100%;
  margin: 0;
  font-size: 100%;
  padding: 0;
  font-family: var(--main-font);

}

a {
  color: #000;
  text-decoration: none;
}

h1, h2, h3, h4, h5 {
  font-weight: bold;
}
h1{
  margin-top: 0;
  padding-top: 0;
}
h2 {
  line-height: 6px;
}

.fett {
  font-weight: bold;
}

#home-container {
  background: #ffc245 url('./asstes/images/1_twa_Rollei.jpg');
  background-position: center center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  margin: 0;
  padding: 6px 0;
  height: 100vh;
  overflow: hidden;
}

.slogan h2 {
  line-height: 0.35em;
  font-family: var(--slogan-font);

}

.word-space-12 {
  word-spacing: 2px;
}

.dunkel-grau {
  color: var(--dunkel-grau);
}

.sehr-dunkel-grau {
  color: var(--sehr-dunkel-grau);
  word-spacing: 0.21em;
}

.word-space-12 {
  word-spacing: 2px;
  margin-top: 0;
}

.word-space-2 {
  word-spacing: 0.015em;
  /* font-size: 1.379em; */
  font-weight: 700;
}

.hell-grau {
  color: var(--hell-grau);
}

.sperren {
  letter-spacing: 0.04em;
  font-weight: 700;
  /* font-size: 41px; */
}



#container {
  display: flex;
  flex-direction: row;
  height: 66vh;
  margin: 1em 3em 0 3em;
  justify-content: space-between;
  /* display: grid;
  grid-template-columns: 1fr fit-content(30%);
  grid-template-rows:1 ;
  grid-column-gap: 0px;
  grid-row-gap: 0px; */
}

#left {
  min-width: 550px;
}
 
.home, .clients, .publications, .exhibitions, .infrastructure, .wachter, .contact, .imprint {
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;

}
.imprint ul{
  text-align: left;
}
#slogan {
  text-align: justify !important;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1.5em;
  padding: 0;
  margin: 0;
}

#slogan a {
  text-decoration: none;
}

#text {
  text-align: right;
  font-size: 0.87em;
  margin-top: 1.5em;
  padding-right: 1em;
  scrollbar-width: thin;
  scrollbar-color: var(--dunkel-grau) #fff;
  /* scroll thumb and track */
  color: #000;
  max-height: 54.8vh;
  max-width: 44vw;
  overflow: auto;
  font-family: Helvetica, Arial, sans-serif;
}

#text p:first-of-type {
  padding-top: 0px;
  margin-top: 0;
}

#text::-webkit-scrollbar {
  width: 5px;
  /* width of the entire scrollbar */
}

#text::-webkit-scrollbar-track {
  background: fff;
  /* color of the tracking area */
}

#text::-webkit-scrollbar-thumb {
  background-color: var(--dunkel-grau);
  /* color of the scroll thumb */
  border-radius: 5px;
  /* roundness of the scroll thumb */
  border: 3px solid fff;
  /* creates padding around scroll thumb */
}

#text h2 {
  display: none;
}

#bottom {
  width: 100%;
  display: block;
  height: 36vh;
  margin: 0;

}

#images {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: repeat(6, 5vw); */
  grid-gap: 1px;
  /* grid-auto-rows: minmax(200px,auto); */
  /* grid-template-rows: 250px; */
  grid-template-rows: 30vh;
  /*  grid-template-rows: 100%;*/
  position: relative;
}

#images.five {
  grid-template-columns: repeat(5, 1fr);

}

#images.five.client {
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr;
}

#images.seven {
  grid-template-columns: repeat(7, 1fr);

}

#images.seven.publications {
  grid-template-columns: 1fr 0.5fr 1fr 0.5fr 0.5fr 0.5fr 1fr;

}

#images.six.infrastructure {
  grid-template-columns: 1fr 0.5fr 1fr 1fr 0.5fr 0.5fr;
}

#images.six.wachter {
  grid-template-columns: 1fr 0.5fr 0.5fr 1fr 1fr 0.5fr;
}

#images.four.contact {
  /* background-color: #ffc245; */
  grid-template-columns: 1fr 1fr 1fr 1fr;

}

#images img {
  border-right: 1px solid #fff;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

#images.special {
  grid-template-columns: auto;

}
#images.full-width {
  grid-template-columns: repeat(1,1fr);
  background-color: #2d2507;
 
} 
#images.full-width img{
  object-fit: contain;
}
#images.special img:nth-of-type(1) {
  /* width: 100%;
  height: auto;
  min-height: 48vh; */
  width: 100%;
  height: auto;
  min-height: 48vh;
  /* object-position: -200px; */

}

@media (min-width:3000px) {
  #images.special img:nth-of-type(1) {
    width: 100%;
    height: auto;
    margin-left: 0;
    object-position: 0;
  }
}

/*  
  #images.special img:nth-of-type(1) {
    object-position: 0;
  }
} */
#images.special img:nth-of-type(2) {
  width: auto;
  position: absolute;
  top: 0;
  height: 330px;
  border-right: none;
  display: none;
}

.people-image {
  display: block;
  position: absolute;
  bottom: -10px;
}

.people-image img {
  /* max-width: 75vw; */
  max-width: 45vw;
  margin-left: 55%;
}

#modal {
  display: block;
  background-color: transparent;
  width: 100%;
  height: 100%;
}

#menu {
  font-size: 1.3em;
}

#menu ul {
  margin-top: 5.3em;
  padding-left: 0;
}

#menu ul li {
  list-style: none;
  line-height: 1.2em;
}

#menu li a {
  text-decoration: unset;
  color: var(--hell-grau);
  font-family: 'Helvetica', sans-serif;
  font-weight: 900;
}

#menu li.current a,
#menu li a:hover {
  color: var(--sehr-dunkel-grau);
}



#menu.burger a {

  text-decoration: none;

}

.menu-icon svg {
  width: 2.5em;
  height: 2.5em;
  color: #999;
}


#menu .burger a:hover {
  color: black;
}

#menu.burger .active {
  color: #000;
}

i {
  padding-left: 10px;
  color: grey;
}

.menu-icon {
  /*   visibility: hidden;*/
  display: none;
}

#left-arrow svg,
#right-arrow svg {
  display: none;
}

@media only screen and (min-width: 2048px) {
  body {
    font-size: 1vw;
  }
}

@media only screen and (max-width: 768px) {
  body {
    overflow-y: hidden;
    /* position: fixed; */
  }
  html {
    overflow: hidden;
    width: 100%;
    }
    body {
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    }
    #root{
      margin-top: 0;
    }
  #home-container {
    background: #ffc245 url('./asstes/images/home-mobile.png');
    background-position: auto center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
   
    position: fixed;
    width: 100%;
    /* background-position: center; */
}


  #left {
    min-width: 274px;
  }

  #text h2 {
    display: block;
    font-size: 1.3em;
    line-height: 1em;
    padding-top: 3px;
    margin-top: 0;
  }

  #menu.burger {
    position: absolute;
    background: white;
    margin-top: -7px;
  }

  #menu ul {

    display: none;
    background: #fff;
    width: 100%;
    margin-top: 0;
    padding: 0 2em 2em 1.6em;
    position: fixed;
    width: 89%;
    left: 0;
    z-index: 999;
    /* box-shadow: 0px 12px 5px -9px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 12px 5px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 12px 5px -9px rgba(0, 0, 0, 0.75); */
  }

  .home #menu ul {
    display: block;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    margin-top: 4em;
  }

  .menu-icon {
    /* visibility: visible; */
    display: block;
    margin-left: -2px;
  }

  #text {
    max-width: none;
    max-height: 33vh;
    margin-top: 3.9em;

  }

  #bottom {
    position: relative
  }

  #text h2 {
    display: block;
  }

  a.icon {
    display: block;
    left: 10px;
  }

  .home #menu.burger {
    display: block;

  }

  #slogan {
    font-size: 1.2em;
  }

 

  .home a.icon {

    display: none;
  }

  #images {
    overflow: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
  }

  /* #images img {
    width: auto;
  } */


  #images.four.contact {
    background-color: #fff;
    grid-template-columns: 1fr 1fr 1fr 1fr;

  }

  #left-arrow {
    /* background: url('./asstes/images/left.png');
    background-size: 35px;
    background-repeat: no-repeat;
    opacity: 0.7;
    background-position: 0 11vh; */

    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    width: 45px;

    /* display: none; */
  }

  #left-arrow svg,
  #right-arrow svg {
    display: block;
    color: #fff;
    font-size: 59px;
    fill: #fff;
    stroke-width: 1px;
    stroke: #c4c4c4;
    width: 42px;
    height: 33px;
    padding-top: 13vh;
    padding-bottom: 13vh;
    cursor: pointer;


    filter: drop-shadow(2px 4px 6px rgb(221, 220, 220));
  }

  #right-arrow {
    /* background:url('./asstes/images/right.png');
    background-size: 35px;
    background-repeat: no-repeat;
    opacity: 0.7;
    background-position: 5px 12vh; */
    top: 0;
    position: absolute;
    right: 0;
    z-index: 99;
    width: 45px;
    /* height: 100vh; */
    /* display: none; */
  }

  #images.special img:nth-of-type(2) {
    display: none;
  }

  .people-image {
    position: absolute;
    bottom: 0;
  }

  .people-image img {
    /* height: 266px; */
    margin-left: 65px;
    /* width: auto; */
    max-width: 100vw;
  }
}

@media only screen and (max-height: 700px) {

  .menu-icon {
    visibility: visible;
    display: block;
  }

  .home #menu ul {
    display: none;
  }

  #menu ul {
    display: none;
    background: #fff;
    width: 100%;
    margin-top: -7px;
    padding: 0 2em 3em 2.5em;
    position: fixed;
    width: 99%;
    left: 0;
    z-index: 999;
    
  }
}

@media screen and (max-height:600px) and (orientation: landscape) {
  #home-container {
    background: #ffc245 url('./asstes/images/home-mobile.png');
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: 1.5em center;
    margin: 0;
    padding: 0;
    position: fixed;
    width: 100vw;
    height: 100vw;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    
  }
  #slogan {
    font-size: 1em;
   }
   #menu ul {
    padding: 0 2em 3em 1.1em;
  }
   .menu-icon svg {
    width: 1.7em;
    height: 1.7em;
  }
  #container{
    height: 57vh;
    margin: 1em 1.5em 0 1.5em;
    }
 
   #text {
    max-height: 44vh;
     margin-top: 16px;
  }
  #text h2 {
    display: block; 
    margin-top: 5px;
    line-height: 1em;
  }

  .home #menu ul {
    margin-top: 0;
  }
  
}
@media screen and (max-height:400px) and (orientation: landscape) {
  html {
    overflow: hidden;
    width: 100%;
    }
    body {
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    }
  #container{
    height: 49vh;
    margin-top: 0;
  }
  
   #text {
     max-height: 36vh;
     margin-top: 20px;
  }
  #menu.burger.open {
    top: 12px;
    width: 100%;
    left: 23px;
    padding-left: 4px;
  }

}

@media screen and (orientation: portrait) { 
  #container{
   height: 51vh;
 }
 #left {
  min-width: 206px;
}
 #images {
  overflow: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
}
  #images img {
    width: auto;
 }
  .home #menu ul {
    display: block;
  }
  .people-image img{
    -webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.1));
    max-width: 100vw;
    margin-left: 0;
  }
  #images img{
    width: auto;
    
  }
  #images.special img{
    width: auto !important;
    height: 1vh !important;
    bottom: 27vh;
  
  }
  .people-image{
    bottom: 13vh;
  }
}
@media screen and (max-width: 480px) and (max-height:700px){
  #menu ul {
    padding: 0 2em 3em 1.5em;
  }
}
@media screen and (max-width:1024px) and (orientation: portrait) {
  #home-container {

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100vh;
}
}
@media screen and (min-width:1024px) and (orientation: portrait) {
  #left {
    min-width: 472px;
}

 #images {
  overflow: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
}
#images img{
  width: auto;
  
}
#images.special img{
  width: auto !important;
  height: 1vh !important;
  bottom: 27vh;

}
.people-image{
  bottom: 13vh;
}
.people-image img{
  -webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.1));
  max-width: 100vw;
  margin-left: 0;
}
}
@media screen and (max-width:480px) and (orientation: portrait) {
  #home-container {

    background-position: 100% 30%;
}
} 
@media only screen and (max-width:480px) {

  #menu.burger {

    background: #fff;
    width: 91%;
}
  .home .menu-icon {
    display: none;
  }

  #container {
    display: flex;
    flex-direction: column;
    margin: 0px 1.5em 0 2em;
    justify-content: normal;
  }

  #images.special img:nth-of-type(1) {
    width: auto;
    height: 48vh;
    /* object-position: -150px; */
  }
}

@media only screen and (max-width:414px) {
  #container {
    height: 56vh;
  }

  #bottom {
    position: fixed;
    bottom: 0;
    height: 31vh;
  }

  .people-image {
    bottom: 30px;
  }
  .people-image img {
    width: 75vh;
    max-width: unset;
    margin-left: 9px;
   }
  #text {
    max-height: 36vh;
    margin-top: 46px;
    font-size: 0.79em;
  }
  #menu ul {
    padding-bottom: 3.5em;
  }
  .home #menu ul {
    margin-top: -7px;
    padding-bottom: 0;
    padding-top: 15px;
  }
  #menu ul {
    width: fit-content; 
  
  }
  #images.special {
    grid-template-rows: 33vh;
  }
  #left-arrow,#right-arrow {
    margin-top: -10px;
  }

  #images.special img:nth-of-type(1) {
    height: 100%;
    min-height: 35vh;
  }
  #images {
    grid-template-rows: 23vh;
    margin-top: 30px;
  }
}
  @media screen and (orientation:landscape) {
  

}  
